import React from "react"
import SEO from "../components/seo"

import Layout from "../components/layout"

const IndexPage = () => (
  <Layout>
    <SEO title="Danton Nguyen Home" />
    <div className="homepage">
      <p className="homepage__intro">
        Hi, I'm Danton,
        <span role="img" aria-label="male emoji">
          🙋🏻‍♂️
        </span>
      </p>
      <p className="homepage__intro">
        <span className="homepage__intro--pink">&lt;frontend </span>
        <span className="homepage__intro--blue">developer&gt;</span>.<br />
        <span className="homepage__intro--close">
          &lt;/frontend developer&gt;
        </span>
      </p>
    </div>
  </Layout>
)

export default IndexPage
